import {createDraftSafeSelector} from '@reduxjs/toolkit';
import {TAppRootState} from 'core/redux/types';
import {brandModuleName} from 'modules/brand/slice';
import {IBrandState} from 'modules/brand/types';

const select = (state: TAppRootState): IBrandState => state[brandModuleName];

export const selectPopularBrandList = createDraftSafeSelector([select], (state) => state.popularBrandList);
export const selectBrandList = createDraftSafeSelector([select], (state) => state.brandList);
export const selectBrandData = createDraftSafeSelector([select], (state) => state.brandData);
